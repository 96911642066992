<template>
  <div class="main">
    <!-- 搜索栏 -->
    <ListToolBar @getList="getList" @onDelete="onDelete" @onCreate="onCreate" />
    <!-- 审计管理 -->
    <custem-table ref="table" :tableInfo="tableInfo" :tableLoading="tableLoading" @getList="pageChange"></custem-table>
    <!-- 表单 -->
    <form-data ref="formRef" @getList="getList"></form-data>
  </div>
</template>

<script setup>
import ListToolBar from './components/ListToolBar'
import { productManagement } from '@/utils/api'
import { onMounted, reactive, ref, getCurrentInstance, inject } from 'vue'
import { ElMessage } from "element-plus";
import FormData from './components/Form.vue'

const searchData = ref({
  page: 1
})
const table = ref()
const tableLoading = ref(false)
const t = inject('t')
const formRef = ref() // 表单对象
const delList = ref([]) // 删除数据
const { proxy } = getCurrentInstance()
// 删除
const deleteData = async (row) => {
  const { id } = row
  if (!!!id) return
  await productManagement.ProductDelete(id)
  ElMessage.success(t('table.del_success'))
  tableInfo.data = tableInfo.data.filter((ele) => ele.id !== id)
}

// 编辑
const editData = async (row) => {
  if (formRef.value) formRef.value.openWin(row.id)
}

// 创建
const onCreate = () => {
  if (!!!formRef.value) return
  formRef.value.openWin()
}

// 多选删除
const onDelete = () => {
  if (!!!delList.value.length) {
    ElMessage.warning(t('table.select_del'))
    return
  }
  delList.value.forEach(item => {
    deleteData(item)
  })
}

const tableInfo = reactive({
  selection: proxy.$userRole(['SuperAdmin']), // 选择框列
  header: [
    { // 产品简称
      key: 'name',
      width: '300px',
      label: t('product.product_abbreviation'),
      fixed: !!!proxy.$userRole(['SuperAdmin'])
    },
    { // 产品全称
      key: 'full_name',
      label: t('product.product_full_name'),
      width: '300px'
    },
    { // 产品类型
      key: 'deployment.name',
      width: '160px',
      label: t('product.product_type'),
      custem: (data) => {
        return data.deployment ? data.deployment.name : '-'
      },
      sortable: true,
      sortBy: (data) => {
        return data.deployment.name
      }
    },
    { // 类型缩写
      key: 'abbreviation',
      width: '180px',
      label: t('product.type_abbreviation'),
      custem: (data) => {
        return data.deployment ? data.deployment.abbreviation : '-'
      },
      sortable: true,
      sortBy: (data) => {
        return data.deployment.abbreviation
      }
    },
    { // 产品大类
      key: 'category.name',
      label: t('product.product_category'),
      width: '180px',
      custem: (data) => {
        return data.category ? data.category.name : '-'
      },
      sortable: true,
      sortBy: (data) => {
        return data.category.name
      }
    },
    { // 大类缩写
      key: 'abbreviation',
      label: t('product.category_abbreviation'),
      width: '200px',
      custem: (data) => {
        return data.category ? data.category.abbreviation : '-'
      },
      sortable: true,
      sortBy: (data) => {
        return data.category.abbreviation
      }
    },
    { // 版本号
      key: 'version',
      label: t('product.version'),
      width: '100px',
      sortable: true,
      sortBy: (data) => {
        return data.version
      }
    },
    { // 产品编码
      key: 'code',
      label: t('product.product_code'),
      minWidth: '170px',
    },
    { // 备注
      key: 'comment',
      minWidth: '170px',
      label: t('user.notes')
    },
    {
      key: 'created_at',
      width: '180px',
      label: t('table.creation_time'),
      sortable: true,
      sortBy: (data) => {
        return data.created_at
      }
    },
    {
      label: t('table.action'),
      width: '140px',
      fixed: 'right',
      hidden: !!!proxy.$userRole(['SuperAdmin']),
      handleFun: [
        {
          name: t('table.modify'),
          fn: editData
        },
        {
          name: t('form.delete'),
          type: 'delete',
          content: t('form.sure_del'),
          fn: deleteData
        },
      ]
    },
  ]
})

// 分页触发改变
const pageChange = (current) => {
  searchData.value.page = current.page
  getList({ ...searchData.value })
}
const getList = async (search = null) => {
  tableLoading.value = true
  searchData.value = search ? { ...search } : searchData.value
  table.value.resetCurrent(searchData.value.page)
  try {
    const res = await productManagement.ProductList({
      ...searchData.value
    })
    tableInfo.data = res.items
    tableInfo.totalPage = res.total
    tableInfo.pageSize = res.per_page
    tableLoading.value = false
  } catch (error) {
    tableLoading.value = false
  }
}

onMounted(() => {
  getList()
})
</script>
